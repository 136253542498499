$body-bg: #efefef;
$nav-tabs-link-active-bg: #fff;
$body-color: #000;

$blue: #0096d6;

$font-family-sans-serif: "HP Simplified", sans-serif;

//$brand-primary:             $blue !default;
//$brand-success:             $green !default;
//$brand-info:                $teal !default;
//$brand-warning:             $orange !default;
//$brand-danger:              $red !default;
//$brand-inverse:             $gray-dark !default;
